import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideNavbar from "./SideNavbar";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { api } from "../services";

function AddProduct() {
  const [productData, setProductData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    productPrice: "",
    categoryId: "",
    imagePath: "",
    calories: "",
    isVisible: true,
    excludeBranches: "",
    productId: "",
    index: "",
  });

  const [imageFile, setImageFile] = useState(null);
  const [branches, setBranches] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [appId, setAppId] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchStoreId();
  }, []);

  useEffect(() => {
    if (storeId) {
      fetchBranches();
      fetchAppIdFromStore(storeId);
    }
  }, [storeId]);

  // Fetch Store ID
  const fetchStoreId = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get("/stores/my", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data?.stores?.length) {
        throw new Error("No stores found.");
      }

      setStoreId(response.data.stores[0]._id);
    } catch (error) {
      console.error("Error fetching store ID:", error.response?.data || error.message);
      setError("Failed to fetch store.");
    }
  };

  // Fetch Branches for Selected Store
  const fetchBranches = async () => {
    if (!storeId) return;

    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/stores/${storeId}/branches`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setBranches(response.data.branches || []);
    } catch (error) {
      console.error("Error fetching branches:", error.response?.data || error.message);
      setError("Failed to fetch branches.");
    }
  };

  // Fetch App ID from Store
  const fetchAppIdFromStore = async (storeId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/stores/${storeId}/apps`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data?.apps?.length) {
        throw new Error("No apps found for the selected store.");
      }

      setAppId(response.data.apps[0]._id);
    } catch (error) {
      console.error("Error fetching App ID:", error.response?.data || error.message);
      setError("Failed to fetch App ID.");
    }
  };

  // Handle Branch Selection
  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch.name);
    setSelectedBranchId(branch._id);
  };

  // Handle Field Input Change
  const handleFieldChange = (field, value) => {
    setProductData((prev) => ({ ...prev, [field]: value }));
  };

  // Handle Image Upload
  const handleImageUpload = async () => {
    if (!imageFile) {
      setError("Image file is required.");
      return null;
    }

    try {
      const formData = new FormData();
      formData.append("image", imageFile);

      const token = localStorage.getItem("token");
      const response = await api.post("/products/upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data.imagePath;
    } catch (error) {
      console.error("Error uploading image:", error.response?.data || error.message);
      setError("Failed to upload image.");
      return null;
    }
  };

  // Handle Save Product
  const handleSave = async () => {
    if (!selectedBranchId) {
      setError("Please select a branch first.");
      return;
    }

    if (!appId) {
      setError("App ID is missing for the selected store.");
      return;
    }

    const uploadedImagePath = await handleImageUpload();
    if (!uploadedImagePath) {
      setError("Image upload failed.");
      return;
    }

    const payload = {
      appId,
      storeId,
      branchId: selectedBranchId,
      productId: productData.productId || null,
      name: { en: productData.nameEn, ar: productData.nameAr },
      description: { en: productData.descriptionEn, ar: productData.descriptionAr },
      productPrice: parseFloat(productData.productPrice),
      categoryId: productData.categoryId,
      imagePath: uploadedImagePath, //  Ensure imagePath is included
      calories: parseInt(productData.calories) || 0,
      isVisible: productData.isVisible,
      excludeBranches: productData.excludeBranches ? productData.excludeBranches.split(",") : [],
      index: parseInt(productData.index) || 0,
    };

    try {
      const token = localStorage.getItem("token");
      console.log(" Sending Create Product Payload:", payload); //  Debugging Log
      const response = await api.post("/products", payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(" Product Added Successfully:", response.data);
      setSuccessMessage("Product added successfully.");
      setTimeout(() => navigate("/catalog/items-list"), 2000);
    } catch (error) {
      console.error(" Error adding product:", error.response?.data || error.message);
      setError(error.response?.data?.message || "Failed to add product.");
    }
  };

  return (
    <>
      <TopNavbar />
      <div className="d-flex dashboard-layout">
        <SideNavbar />
        <MDBContainer fluid className="p-4 content-area flex-grow-1">
          <h2 className="mb-4">Add Product</h2>
          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label>Select Branch</label>
              <MDBDropdown>
                <MDBDropdownToggle color="light">
                  {selectedBranch || "Select Branch"}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {branches.map((branch) => (
                    <MDBDropdownItem key={branch._id} onClick={() => handleBranchSelect(branch)}>
                      {branch.name}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>

            <MDBCol md="6" className="mb-3">
              <label>Upload Image</label>
              <input type="file" className="form-control" onChange={(e) => setImageFile(e.target.files[0])} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            {["nameEn", "nameAr", "descriptionEn", "descriptionAr", "productPrice", "categoryId", "calories", "excludeBranches", "productId", "index"].map((field) => (
              <MDBCol md="6" className="mb-3" key={field}>
                <MDBInput label={field.replace(/([A-Z])/g, " $1")} value={productData[field]} onChange={(e) => handleFieldChange(field, e.target.value)} />
              </MDBCol>
            ))}
          </MDBRow>

          <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
            Save
          </MDBBtn>

          {error && <p className="text-danger mt-3">{error}</p>}
          {successMessage && <p className="text-success mt-3">{successMessage}</p>}
        </MDBContainer>
      </div>
    </>
  );
}

export default AddProduct;
