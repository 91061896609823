import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideNavbar from "./SideNavbar";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { api } from "../services";

function AddCategory() {
  const [categoryData, setCategoryData] = useState({
    nameEn: "",
    nameAr: "",
    index: "",
    excludeBranches: "",
  });

  const [branches, setBranches] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [appId, setAppId] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchStoreId();
  }, []);

  useEffect(() => {
    if (storeId) {
      fetchBranches();
      fetchAppIdFromStore(storeId);
    }
  }, [storeId]);

  // Fetch Store ID
  const fetchStoreId = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get("/stores/my", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data?.stores?.length) {
        throw new Error("No stores found.");
      }

      setStoreId(response.data.stores[0]._id);
    } catch (error) {
      console.error("Error fetching store ID:", error.response?.data || error.message);
      setError("Failed to fetch store.");
    }
  };

  // Fetch Branches for Selected Store
  const fetchBranches = async () => {
    if (!storeId) return;

    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/stores/${storeId}/branches`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setBranches(response.data.branches || []);
    } catch (error) {
      console.error("Error fetching branches:", error.response?.data || error.message);
      setError("Failed to fetch branches.");
    }
  };

  // Fetch App ID from Store
  const fetchAppIdFromStore = async (storeId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/stores/${storeId}/apps`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data?.apps?.length) {
        throw new Error("No apps found for the selected store.");
      }

      setAppId(response.data.apps[0]._id);
    } catch (error) {
      console.error("Error fetching App ID:", error.response?.data || error.message);
      setError("Failed to fetch App ID.");
    }
  };

  // Handle Branch Selection
  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch.name);
    setSelectedBranchId(branch._id);
  };

  // Handle Field Input Change
  const handleFieldChange = (field, value) => {
    setCategoryData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (!selectedBranchId) {
        setError("Please select a branch first.");
        return;
    }

    if (!appId) {
        setError("App ID is missing for the selected store.");
        return;
    }

    const payload = {
        appId,  
        storeId,  
        branchId: selectedBranchId,  
        nameAr: categoryData.nameAr,
        nameEn: categoryData.nameEn,
        index: parseInt(categoryData.index) || 0,
        excludeBranches: categoryData.excludeBranches ? categoryData.excludeBranches.split(",") : [],
    };

    try {
        const token = localStorage.getItem("token");
        console.log("🔄 Sending Create Category Payload:", payload);

        //  Correct API URL
        const response = await api.post(`/apps/${appId}/categories`, payload, {
            headers: { Authorization: `Bearer ${token}` },
        });

        console.log(" Category Added Successfully:", response.data);
        setSuccessMessage("Category added successfully.");
        setTimeout(() => navigate("/categories-listing"), 2000);
    } catch (error) {
        console.error(" Error adding category:", error.response?.data || error.message);
        setError(error.response?.data?.message || "Failed to add category.");
    }
};



  return (
    <>
      <TopNavbar />
      <div className="d-flex dashboard-layout">
        <SideNavbar />
        <MDBContainer fluid className="p-4 content-area flex-grow-1">
          <h2 className="mb-4">Add Category</h2>
          <MDBRow>
            <MDBCol md="6">
              <label>Select Branch</label>
              <MDBDropdown>
                <MDBDropdownToggle color="light">{selectedBranch || "Select Branch"}</MDBDropdownToggle>
                <MDBDropdownMenu>
                  {branches.map((branch) => (
                    <MDBDropdownItem key={branch._id} onClick={() => handleBranchSelect(branch)}>
                      {branch.name}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-3">
            <MDBCol md="6">
              <MDBInput label="Category Name (English)" type="text" value={categoryData.nameEn} onChange={(e) => handleFieldChange("nameEn", e.target.value)} />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput label="Category Name (Arabic)" type="text" value={categoryData.nameAr} onChange={(e) => handleFieldChange("nameAr", e.target.value)} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-3">
            <MDBCol md="6">
              <MDBInput label="Index" type="number" value={categoryData.index} onChange={(e) => handleFieldChange("index", e.target.value)} />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput label="Exclude Branches (comma separated)" type="text" value={categoryData.excludeBranches} onChange={(e) => handleFieldChange("excludeBranches", e.target.value)} />
            </MDBCol>
          </MDBRow>

          {error && <p className="text-danger mt-3">{error}</p>}
          {successMessage && <p className="text-success mt-3">{successMessage}</p>}

          <MDBRow className="mt-4">
            <MDBCol>
              <MDBBtn color="secondary" outline onClick={() => navigate("/categories-listing")}>
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
                Save
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </>
  );
}

export default AddCategory;
