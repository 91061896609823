import React, { useState, useEffect } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import { useNavigate } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBBadge,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBInput,
    MDBTableHead,
    MDBIcon,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function Orders() {
    const [activeTab, setActiveTab] = useState('All');
    const [viewMode, setViewMode] = useState('list'); // State for list or grid view
    const [orders, setOrders] = useState([]); // State for fetched orders
    const [filteredOrders, setFilteredOrders] = useState([]); // State for filtered orders
    const [apps, setApps] = useState([]); // State for integrated apps
    const [selectedApp, setSelectedApp] = useState('Select App');
    const [selectedAppId, setSelectedAppId] = useState(null); // Track selected app ID
    const [orderSummary, setOrderSummary] = useState({ total: 0, pending: 0, completed: 0, cancelled: 0 });
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [pagination, setPagination] = useState({
        totalOrders: 0,
        totalPages: 1,
        currentPage: 1,
    });
    const [expandedOrderId, setExpandedOrderId] = useState(null);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('Select Branch');
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [storeId, setStoreId] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Add this line to declare `isLoading`
    const [selectedDate, setSelectedDate] = useState(''); // To store the selected date

    useEffect(() => {
        fetchApps(); // Fetch integrated apps on component mount
        fetchAllOrders(); // Fetch all orders initially
        fetchBranches();
        fetchStoreId();
        listenForJahezOrders();
    }, []);
    useEffect(() => {
        if (storeId) {
            fetchBranches();
        }
    }, [storeId]);

    useEffect(() => {
        filterOrders(); // Reapply filters whenever filter states or orders update
    }, [activeTab, searchQuery, selectedDate, orders]);

    const fetchStoreId = async () => {
        try {
            console.log('Fetching storeId...');
            const token = localStorage.getItem('token');
            const response = await api.get('/stores/my', {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Ensure response has the expected structure
            if (!response.data || !Array.isArray(response.data.stores) || response.data.stores.length === 0) {
                throw new Error('No stores found in the response');
            }

            // Extract the first store's ID
            const myStoreId = response.data.stores[0]._id;
            console.log('Store ID fetched:', myStoreId);
            setStoreId(myStoreId);
        } catch (error) {
            console.error('Error fetching storeId:', error.response?.data || error.message);
        }
    };


    const listenForJahezOrders = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await api.get("/webhooks/jahez/orders", {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (response.data.success && response.data.orders) {
                setOrders((prevOrders) => [...prevOrders, ...response.data.orders]);
                setFilteredOrders((prevOrders) => [...prevOrders, ...response.data.orders]);
                calculateSummary([...orders, ...response.data.orders]);
            }
        } catch (err) {
            console.error("Error fetching Jahez webhook orders:", err.message);
        }
    };
    const registerJahezWebhook = async () => {
        try {
            const userToken = localStorage.getItem('token'); // User authentication token
    
            //  Fetch the app integration details to get Jahez API Key
            const appResponse = await api.get("/apps", {
                headers: { Authorization: `Bearer ${userToken}` }
            });
    
            const app = appResponse.data.apps.find(app => app.application === "Jahez");
    
            if (!app || !app.accessToken || !app.clientId) {
                console.error(" No valid Jahez app integration found. Please check Jahez API key.");
                return;
            }
    
            const headers = {
                Authorization: `Bearer ${userToken}`, // User Token for authentication
                "x-api-key": app.clientId, //  Ensure correct Jahez API key is being sent
                "Content-Type": "application/json",
            };
    
            console.log("🔹 Sending Headers:", headers);
    
            //  Register webhook with correct headers
            const response = await api.post(
                "/webhooks/jahez_create_endpoint",
                { url: "https://api.demo.loops.sa/webhooks/jahez_create" },
                { headers }
            );
    
            if (response.data.success) {
                console.log(" Jahez Webhook registered successfully");
            } else {
                console.error(" Failed to register Jahez webhook:", response.data);
            }
        } catch (error) {
            console.error(" Error registering Jahez webhook:", error.response?.data || error.message);
        }
    };
    
    // const registerJahezWebhook = async () => {
    //     try {
    //         const userToken = localStorage.getItem("token"); //  User's Auth Token
    //         if (!userToken) {
    //             console.error(" User Token is missing");
    //             return;
    //         }
    
    //         //  Fetch the app integration details to get the Jahez API Token
    //         const appResponse = await api.get("/stores/my", {
    //             headers: { Authorization: `Bearer ${userToken}` }, // Use user token to fetch app details
    //         });
    
    //         if (!appResponse.data || appResponse.data.apps.length === 0) {
    //             console.error(" No apps found for the user");
    //             return;
    //         }
    
    //         const app = appResponse.data.apps.find(a => a.application === "Jahez");
    //         if (!app || !app.accessToken) {
    //             console.error(" Jahez App Integration Not Found");
    //             return;
    //         }
    
    //         const jahezToken = app.accessToken;
    
    //         const response = await api.post(
    //             "/webhooks/jahez_create_endpoint",
    //             { url: "https://api.demo.loops.sa/webhooks/jahez_create" },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${jahezToken}`, //  Use Jahez API Token
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         );
    
    //         console.log(" Jahez Webhook registered successfully", response.data);
    //     } catch (error) {
    //         console.error(" Error registering Jahez webhook:", error.response?.data || error.message);
    //     }
    // };
    
    // Call this function on mount
    useEffect(() => {
        registerJahezWebhook();
    }, []);


    const filterOrders = () => {
        let updatedOrders = [...orders];

        // Filter by status
        if (activeTab !== 'All') {
            updatedOrders = updatedOrders.filter((order) => order.status === activeTab);
        }

        // Filter by search query
        if (searchQuery) {
            updatedOrders = updatedOrders.filter((order) =>
                order.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Filter by selected date
        if (selectedDate) {
            updatedOrders = updatedOrders.filter(
                (order) =>
                    new Date(order.createdAt).toISOString().split('T')[0] === selectedDate
            );
        }

        setFilteredOrders(updatedOrders); // Update the filtered orders state
    };


    const fetchApps = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setApps(response.data.apps || []);
        } catch (err) {
            console.error('Error fetching apps:', err.message);
        }
    };

    const handleViewDetails = (orderId) => {
        navigate(`/orders/${orderId}`); // Navigate to the order details page
    };

    const toggleOrderDetails = (orderId) => {
        setExpandedOrderId(orderId === expandedOrderId ? null : orderId);
    };

    const handleCheckboxChange = (orderId) => {
        setSelectedOrders((prevSelected) =>
            prevSelected.includes(orderId)
                ? prevSelected.filter((id) => id !== orderId)
                : [...prevSelected, orderId]
        );
    };

    const fetchAllOrders = async (page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/orders?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { orders: fetchedOrders, totalOrders, totalPages } = response.data;
            setOrders(fetchedOrders);
            setFilteredOrders(fetchedOrders); // Initialize filtered orders
            calculateSummary(fetchedOrders); // Update order summary
            setPagination({ totalOrders, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching orders:', err.message);
        }
    };

    const handleOrderClick = async (orderId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/orders/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSelectedOrderDetails(response.data);
            setModalOpen(true);
        } catch (err) {
            console.error('Error fetching order details:', err.response?.data || err.message);
            alert('Failed to fetch order details. Please try again.');
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedOrderDetails(null);
    };

    const calculateSummary = (orders) => {
        const summary = { total: orders.length, pending: 0, completed: 0, cancelled: 0 };
        orders.forEach((order) => {
            if (order.status === 'Pending') summary.pending += 1;
            else if (order.status === 'Completed') summary.completed += 1;
            else if (order.status === 'Cancelled') summary.cancelled += 1;
        });
        setOrderSummary(summary);
    };

    const filterOrdersByStatus = (status) => {
        if (status === 'All') {
            setFilteredOrders(orders);
        } else {
            const filtered = orders.filter((order) => order.status === status);
            setFilteredOrders(filtered);
        }
        setActiveTab(status);
    };

    const handleAppSelect = (app) => {
        setSelectedApp(app.application);
        setSelectedAppId(app._id);
        fetchOrdersByApp(app._id, 1); // Fetch orders for the selected app (page 1)
    };

    const fetchBranches = async () => {
        if (!storeId) {
            console.error('Store ID is not set. Cannot fetch branches.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/stores/${storeId}/branches`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBranches(response.data.branches || []);
        } catch (err) {
            console.error('Error fetching branches:', err.response?.data || err.message);
            alert('Failed to fetch branches. Please try again later.');
        }
    };



    // Handle branch selection
    const handleBranchSelect = (branch) => {
        setSelectedBranch(branch.name);
        setSelectedBranchId(branch._id);
        fetchOrdersByBranch(branch._id, 1); // Fetch orders for the selected branch (page 1)
    };


    const fetchOrdersByBranch = async (branchId, page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/branches/${branchId}/orders?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { orders: fetchedOrders, totalOrders, totalPages } = response.data;
            setOrders(fetchedOrders);
            setFilteredOrders(fetchedOrders); // Initialize filtered orders
            calculateSummary(fetchedOrders); // Update order summary
            setPagination({ totalOrders, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching orders by branch:', err.response?.data || err.message);
        }
    };

    // const handleAction = async (orderId, action) => {
    //     try {
    //         const token = localStorage.getItem('token');

    //         // Send API request
    //         const response = await api.patch(
    //             `/orders/${orderId}/status`,
    //             { status: action === 'Accept' ? 'In Progress' : action },
    //             { headers: { Authorization: `Bearer ${token}` } }
    //         );

    //         // Update order status locally
    //         setFilteredOrders((prevOrders) =>
    //             prevOrders.map((order) =>
    //                 order.order_id === orderId
    //                     ? { ...order, status: response.data.order.status }
    //                     : order
    //             )
    //         );

    //         console.log('Order status updated:', response.data);
    //     } catch (error) {
    //         console.error('Error updating order status:', error.response?.data || error.message);
    //         alert('Failed to update order status. Please try again.');
    //     }
    // };

    const handleAction = async (orderId, action) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.patch(`/orders/${orderId}/status`, {
                status: action === 'Accept' ? 'A' : action === 'Reject' ? 'R' : action,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Send status update to Jahez Webhook
            if (response.data.success) {
                await api.post('/webhooks/status_update', {
                    jahezOrderId: orderId,
                    status: action === 'Accept' ? 'A' : action === 'Reject' ? 'R' : action,
                    reason: action === 'Reject' ? 'Item not available' : ''
                });
            }

            setFilteredOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.order_id === orderId
                        ? { ...order, status: response.data.order.status }
                        : order
                )
            );

            console.log('Order status updated:', response.data);
        } catch (error) {
            console.error('Error updating order status:', error.response?.data || error.message);
            alert('Failed to update order status. Please try again.');
        }
    };

    const fetchOrdersByApp = async (appId, page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/apps/${appId}/orders?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { orders: fetchedOrders, totalOrders, totalPages } = response.data;
            setOrders(fetchedOrders);
            setFilteredOrders(fetchedOrders); // Initialize filtered orders
            calculateSummary(fetchedOrders); // Update order summary
            setPagination({ totalOrders, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching orders by app:', err.response?.data || err.message);
        }
    };


    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredOrders(orders);
        } else {
            const filtered = orders.filter((order) =>
                order.customer_name.toLowerCase().includes(query) ||
                order.order_id.toLowerCase().includes(query) ||
                order.platform.toLowerCase().includes(query)
            );
            setFilteredOrders(filtered);
        }
    };

    const sortOrders = (order) => {
        const sorted = [...filteredOrders].sort((a, b) => {
            if (order === 'recent') {
                return new Date(b.createdAt) - new Date(a.createdAt); // Newest first
            } else {
                return new Date(a.createdAt) - new Date(b.createdAt); // Oldest first
            }
        });
        setFilteredOrders(sorted);
    };

    const handleQuickAction = async (action) => {
        if (!selectedOrders.length) {
            alert('No orders selected for this action.');
            return;
        }

        try {
            setIsLoading(true); // Show loading indicator
            const token = localStorage.getItem('token');
            const response = await api.patch(
                '/orders/bulk-status',
                {
                    orderIds: selectedOrders,
                    status: action === 'Accept' ? 'In Progress' : action,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setFilteredOrders((prevOrders) =>
                prevOrders.map((order) =>
                    selectedOrders.includes(order.order_id)
                        ? { ...order, status: action === 'Accept' ? 'In Progress' : action }
                        : order
                )
            );

            alert(`Orders have been updated to "${action === 'Accept' ? 'In Progress' : action}"`);
            setSelectedOrders([]); // Clear selected orders after update
        } catch (error) {
            console.error(`Error performing quick action (${action}):`, error.response?.data || error.message);
            alert('Failed to update order statuses. Please try again.');
        } finally {
            setIsLoading(false); // Hide loading indicator
        }
    };

    const handlePageChange = (page) => {
        if (selectedAppId) {
            fetchOrdersByApp(selectedAppId, page);
        } else {
            fetchAllOrders(page);
        }
    };
    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">All Orders</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Orders</h6>
                                    <h3 className="mb-0">{orderSummary.total}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Pending Orders</h6>
                                    <h3 className="mb-0">{orderSummary.pending}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Completed Orders</h6>
                                    <h3 className="mb-0">{orderSummary.completed}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Cancelled Orders</h6>
                                    <h3 className="mb-0">{orderSummary.cancelled}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    {/* Quick Actions */}
                    <MDBRow className="mb-4">
                        <MDBCol className="d-flex gap-2 justify-content-start">
                            <MDBBtn color="info" outline>
                                Turn off Apps
                            </MDBBtn>
                            <MDBBtn
                                color="success"
                                outline
                                onClick={() => handleQuickAction('Accept')}
                                disabled={!selectedOrders.length || isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Accept all orders'}
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                outline
                                onClick={() => handleQuickAction('Reject')}
                                disabled={!selectedOrders.length || isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Reject all orders'}
                            </MDBBtn>
                            <MDBBtn
                                color="warning"
                                outline
                                onClick={() => handleQuickAction('Update Timings')}
                                disabled={!selectedOrders.length || isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Update Timings'}
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="align-items-center mb-4">
                        {/* Branch Selector */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">{selectedBranch}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {branches.map((branch) => (
                                        <MDBDropdownItem
                                            key={branch._id}
                                            onClick={() => {
                                                setSelectedBranch(branch.name);
                                                handleBranchSelect(branch);
                                            }}
                                        >
                                            {branch.name}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Date Filter */}
                        <MDBCol md="2">
                            <MDBInput
                                type="date"
                                label="Filter by Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </MDBCol>

                        {/* Search Bar */}
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search orders"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </MDBCol>

                        {/* Filter Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBIcon fas icon="filter" className="me-2" />
                                <MDBDropdownToggle color="secondy">Filters By</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem header>Status</MDBDropdownItem>
                                    {['All', 'Pending', 'In Progress', 'Cancelled', 'Completed'].map((status) => (
                                        <MDBDropdownItem
                                            key={status}
                                            onClick={() => setActiveTab(status)}
                                        >
                                            {status}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="1">
                            <MDBDropdown>
                                <MDBDropdownToggle>Sort by</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={() => sortOrders('recent')}>
                                        Recent
                                    </MDBDropdownItem>
                                    <MDBDropdownItem onClick={() => sortOrders('oldest')}>
                                        Oldest
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* View Mode Toggle */}
                        <MDBCol md="2" className="text-end">
                            <MDBBtn
                                color="light"
                                onClick={() => setViewMode('list')}
                                active={viewMode === 'list'}
                            >
                                List
                            </MDBBtn>
                            <MDBBtn
                                color="light"
                                onClick={() => setViewMode('grid')}
                                active={viewMode === 'grid'}
                            >
                                Grid
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>


                    {/* Orders Tabs */}
                    <MDBTabs className="mb-3">
                        {['All', 'Pending', 'Completed', 'Cancelled'].map((tab) => (
                            <MDBTabsItem key={tab}>
                                <MDBTabsLink
                                    onClick={() => filterOrdersByStatus(tab)}
                                    active={activeTab === tab}
                                >
                                    {tab}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        ))}
                    </MDBTabs>

                    {/* Orders Content */}
                    {viewMode === 'grid' ? (
                        <MDBRow>
                            {filteredOrders.map((order) => (
                                <MDBCol className="mb-4" md="4" key={order._id}>
                                    <MDBCard className="order-card h-100">
                                        <MDBCardBody>
                                            <div className="d-flex align-items-start">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input me-3"
                                                    onChange={() => handleCheckboxChange(order.order_id)}
                                                    checked={selectedOrders.includes(order.order_id)}
                                                />
                                                <div>
                                                    <h5 className="mb-2">
                                                        Order ID: <a href={`#order-${order.order_id}`}>{order.order_id}</a>
                                                    </h5>
                                                    <div className="mb-2">
                                                        <strong>{order.customer_name}</strong>
                                                        <div>{order.createdAt ? new Date(order.createdAt).toLocaleString() : 'N/A'}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Expandable Content */}
                                            {expandedOrderId === order._id && (
                                                <div className="mt-3">
                                                    <h6>Items:</h6>
                                                    <ul>
                                                        {order.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                {item.quantity} x {item.name} - {item.size || ''} ${item.price}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p>
                                                        <strong>VAT:</strong> {order.vat || 0}% <br />
                                                        <strong>Total:</strong> ${order.total_price.toFixed(2)}
                                                    </p>
                                                </div>
                                            )}

                                            <div className="mt-3 d-flex align-items-center">
                                                {/* Dynamically render platform logo */}
                                                {order.platform === 'Amazon' && (
                                                    <img src="https://www.amazon.com/favicon.ico" alt="Amazon" style={{ height: '30px' }} />
                                                )}
                                                {order.platform === 'Noon' && (
                                                    <img src="https://f.nooncdn.com/s/app/com/noon/icons/favicon_v4/apple-touch-icon.png" alt="Noon" style={{ height: '30px' }} />
                                                )}
                                                {order.platform === 'Jahez' && (
                                                    <img src="/path/to/jahez-logo.png" alt="Jahez" style={{ height: '30px' }} />
                                                )}
                                                <span>{order.platform}</span>
                                            </div>

                                            {/* Action Buttons */}
                                            <div className="d-flex justify-content-between mt-3">
                                                <MDBBtn
                                                    color="link"
                                                    size="sm"
                                                    onClick={() => toggleOrderDetails(order._id)}
                                                    className="p-0"
                                                >
                                                    <i
                                                        className={`fas ${expandedOrderId === order._id
                                                            ? 'fa-chevron-up'
                                                            : 'fa-chevron-down'
                                                            }`}
                                                    ></i>{' '}
                                                    {expandedOrderId === order._id ? 'Hide Details' : 'Show Details'}
                                                </MDBBtn>
                                                {order.status === 'Pending' ? (
                                                    <div>
                                                        <MDBBtn
                                                            color="danger"
                                                            outline
                                                            size="sm"
                                                            className="me-2"
                                                            onClick={() => handleAction(order._id, 'Reject')}
                                                        >
                                                            Reject
                                                        </MDBBtn>
                                                        <MDBBtn
                                                            color="success"
                                                            outline
                                                            size="sm"
                                                            onClick={() => handleAction(order._id, 'Accept')}
                                                        >
                                                            Accept
                                                        </MDBBtn>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <MDBBtn
                                                            color="info"
                                                            outline
                                                            size="sm"
                                                            className="me-2"
                                                            onClick={() => handleAction(order._id, 'Complete')}
                                                        >
                                                            Complete
                                                        </MDBBtn>
                                                        <MDBBtn
                                                            color="warning"
                                                            outline
                                                            size="sm"
                                                            onClick={() => handleAction(order._id, 'Cancel')}
                                                        >
                                                            Cancel
                                                        </MDBBtn>
                                                    </div>
                                                )}
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    ) : (
                        <MDBTable responsive>
                            <MDBTableHead className="table-header">
                                <tr>
                                    <th className="custom-th">
                                        <MDBInput
                                            type="checkbox"
                                            onChange={(e) =>
                                                setSelectedOrders(
                                                    e.target.checked ? filteredOrders.map((order) => order.order_id) : []
                                                )
                                            }
                                            checked={selectedOrders.length > 0 && selectedOrders.length === filteredOrders.length}
                                        />
                                    </th>
                                    <th className="custom-th">Platform</th>
                                    <th className="custom-th">Order ID</th>
                                    <th className="custom-th">Customer</th>
                                    <th className="custom-th">Items</th>
                                    <th className="custom-th">Details</th>
                                    <th className="custom-th">Status</th>
                                    <th className="custom-th">Actions</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className="table-row">
                                {filteredOrders.map((order) => (
                                    <React.Fragment key={order.order_id}> {/* Consistent key */}
                                        <tr>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => handleCheckboxChange(order.order_id)} // Use order_id
                                                    checked={selectedOrders.includes(order.order_id)}
                                                />
                                            </td>
                                            <td>
                                                {/* Dynamically render platform logo */}
                                                {order.platform === 'Amazon' && (
                                                    <img src="https://www.amazon.com/favicon.ico" alt="Amazon" style={{ height: '30px' }} />
                                                )}
                                                {order.platform === 'Noon' && (
                                                    <img src="https://f.nooncdn.com/s/app/com/noon/icons/favicon_v4/apple-touch-icon.png" alt="Noon" style={{ height: '30px' }} />
                                                )}
                                                {order.platform === 'Jahez' && (
                                                    <img src="/path/to/jahez-logo.png" alt="Jahez" style={{ height: '30px' }} />
                                                )}
                                            </td>
                                            <td>
                                                <a
                                                    href="#!"
                                                    onClick={() => handleOrderClick(order.order_id)} // Use order_id for actions
                                                    className="text-primary"
                                                >
                                                    {order.order_id} {/* Display order_id */}
                                                </a>
                                            </td>
                                            <td>
                                                <strong>{order.customer_name}</strong>
                                                <div>{new Date(order.createdAt).toLocaleString()}</div>
                                                {expandedOrderId === order.order_id && ( // Expanded details use order_id
                                                    <div className="pt-2">
                                                        <strong>Email:</strong> {order.customer_email} <br />
                                                        <strong>Phone:</strong> {order.customer_phone}
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                {order.items[0].quantity} x {order.items[0].name} (${order.items[0].price})
                                                {order.items.length > 1 && <span> +{order.items.length - 1} more</span>}
                                                {expandedOrderId === order.order_id && (
                                                    <ul className="pt-2">
                                                        {order.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                {item.quantity} x {item.name} (${item.price})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </td>
                                            <td>
                                                VAT: {order.vat || 0}% - Total: ${order.total_price.toFixed(2)}
                                            </td>
                                            <td>
                                                <MDBBadge
                                                    color={
                                                        order.status === 'Pending'
                                                            ? 'warning'
                                                            : order.status === 'Completed'
                                                                ? 'success'
                                                                : 'danger'
                                                    }
                                                    pill
                                                >
                                                    {order.status}
                                                </MDBBadge>
                                            </td>
                                            <td>
                                                <MDBBtn
                                                    color="link"
                                                    size="sm"
                                                    onClick={() => toggleOrderDetails(order.order_id)} // Use order_id
                                                    className="p-0"
                                                >
                                                    <i
                                                        className={`fas ${expandedOrderId === order.order_id
                                                            ? 'fa-chevron-up'
                                                            : 'fa-chevron-down'
                                                            }`}
                                                    ></i>
                                                </MDBBtn>
                                                {expandedOrderId === order.order_id && (
                                                    <div className="pt-2">
                                                        {order.status === 'Pending' ? (
                                                            <>
                                                                <MDBBtn
                                                                    color="success"
                                                                    outline
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleAction(order.order_id, 'Accept')} // Use order_id
                                                                >
                                                                    Accept
                                                                </MDBBtn>
                                                                <MDBBtn
                                                                    color="danger"
                                                                    outline
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleAction(order.order_id, 'Reject')} // Use order_id
                                                                >
                                                                    Reject
                                                                </MDBBtn>
                                                            </>
                                                        ) : order.status === 'N' && order.platform === 'Jahez' ? (
                                                            <>
                                                                <MDBBtn
                                                                    color="success"
                                                                    outline
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleAction(order.order_id, 'Accept')} // Send status to Jahez
                                                                >
                                                                    Accept (Jahez)
                                                                </MDBBtn>
                                                                <MDBBtn
                                                                    color="danger"
                                                                    outline
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleAction(order.order_id, 'Reject')} // Send status to Jahez
                                                                >
                                                                    Reject (Jahez)
                                                                </MDBBtn>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <MDBBtn
                                                                    color="success"
                                                                    outline
                                                                    size="sm"
                                                                    className="me-2 mb-4"
                                                                    onClick={() => handleAction(order.order_id, 'Complete')} // Use order_id
                                                                >
                                                                    Complete
                                                                </MDBBtn>
                                                                <MDBBtn
                                                                    className="mb-4"
                                                                    color="info"
                                                                    size="sm"
                                                                    outline
                                                                    onClick={() => handleViewDetails(order.order_id)}
                                                                >
                                                                    View Details
                                                                </MDBBtn>
                                                                <MDBBtn
                                                                    className="mb-4"
                                                                    color="warning"
                                                                    outline
                                                                    size="sm"
                                                                    onClick={() => handleAction(order.order_id, 'Cancel')} // Use order_id
                                                                >
                                                                    Cancel
                                                                </MDBBtn>
                                                            </>
                                                        )}
                                                    </div>
                                                )}

                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </MDBTableBody>

                        </MDBTable>
                    )}
                    {/* Modal for Order Details */}
                    {selectedOrderDetails && (
                        <div
                            className={`modal fade ${modalOpen ? 'show' : ''}`}
                            style={{ display: modalOpen ? 'block' : 'none' }}
                            tabIndex="-1"
                            role="dialog"
                        >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Order Details</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            onClick={closeModal}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            <strong>Order ID:</strong> #{selectedOrderDetails.order_id}
                                        </p>
                                        <p>
                                            <strong>Customer:</strong> {selectedOrderDetails.customer_name}
                                        </p>
                                        <p>
                                            <strong>Items:</strong>
                                        </p>
                                        <ul>
                                            {selectedOrderDetails.items.map((item, idx) => (
                                                <li key={idx}>
                                                    {item.quantity} x {item.name} (${item.price})
                                                </li>
                                            ))}
                                        </ul>
                                        <p>
                                            <strong>Total Cost:</strong> ${selectedOrderDetails.total_price}
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Pagination */}
                    <MDBRow className="mt-4">
                        <MDBCol>
                            <div className="d-flex justify-content-between align-items-center">
                                <MDBBtn
                                    disabled={pagination.currentPage === 1}
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                >
                                    Previous
                                </MDBBtn>
                                <span>
                                    Page {pagination.currentPage} of {pagination.totalPages}
                                </span>
                                <MDBBtn
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                >
                                    Next
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default Orders;
